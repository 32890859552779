import React from "react";
import axios from "axios";
import PriceDetailsCard from "./PriceDetailsCard";
import { postCreateOrder } from "../../api/apiServices";
import { useHistory } from "react-router-dom";
import {
  domesticShippingRates,
  internationalShippingRates,
  freeShippingThreshold,
} from "../../data/index";
import initiatePayment from "../payments/initiatePayment";

const SummaryCard = ({ cartData, selectedAddressId }) => {
  const history = useHistory();

  const handlePayment = async () => {
    try {
      await initiatePayment(
        cartData.order_id,
        calculateShippingCharges(cartData.order_items, selectedAddressId) +
        calculateTotalPriceSum(cartData.order_items)
      );
      console.log("Payment initiated");
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  const handlePlaceOrder = async () => {
    try {
      if (!selectedAddressId) {
        alert("Please select an address");
        return;
      }
      // else {
      //   console.log(selectedAddressId);
      //   if (!selectedAddressId.pincode) {
      //     alert("Please select the pincode");
      //     return;
      //   }
      //   const payload = {
      //     "orgPincode": "431203",
      //     "desPincode": selectedAddressId.pincode.toString()
      //   };
      //   axios({
      //     method: "post",
      //     url: "http://smarttrack.ctbsplus.dtdc.com/ratecalapi/PincodeApiCall",
      //     // url: "https://cors-anywhere.herokuapp.com/http://smarttrack.ctbsplus.dtdc.com/ratecalapi/PincodeApiCall",
      //     data: payload,
      //     headers: {
      //       "Content-Type": "application/json"
      //     }
      //   })
      //     .then((response) => {
      //       console.log(response.data.data);

      //       if (response.data?.PIN_CITY[1] !== "FULLY SERVICEABLE") {
      //         alert("Your Parcel will arrive via a courier service other than DTDC.");
      //       } else {
      //         console.log("Servicable");
      //         // return;
      //         // return enqueueSnackbar(response.data.message, { variant: 'error' });
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //       alert("Please try again later");
      //       return;
      //     });
      // }
      const response = await postCreateOrder({
        address_id: selectedAddressId?.address_id,
        // status :"cart"
      });
      if (response.data.success === true) {
        handlePayment();
      }
    } catch (error) {
      alert("Error placing order:");
    }
  };

  const calculateTotalPriceSum = (cartItems) => {
    if (!cartItems || cartItems.length === 0) {
      return 0;
    }
    const totalPriceSum = cartItems.reduce((sum, item) => {
      return sum + (item.product_details.selling_price * item.quantity || 0);
    }, 0);

    return totalPriceSum;
  };

  const calculateShippingCharges = (cartItems, selectedAddressId) => {
    if (!cartItems || cartItems.length === 0) {
      return 0;
    }
    // Check if there are any liquid items
    // const hasliquidItems = cartItems.some(item => item.product_details.product_type === 'Liquid');
    // console.log(hasliquidItems);
    // if(hasliquidItems){
    //   return 0;
    // }

    const nonliquidItems = cartItems.filter(
      (item) =>
        item.product_details.product_type !== "Liquid" &&
        item.product_id !== 500
    );


    let shippingCharge = 0;
    // console.log(nonliquidItems);
    const totalCDCount = nonliquidItems.reduce(
      (sum, item) => sum + (item.cd_count * item.quantity || 0),
      0
    );
    
    // const totalCDCount = cartItems.reduce(
    //   (sum, item) => sum + (item.cd_count * item.quantity || 0),
    //   0
    // );
    if(totalCDCount === 0){
      return 0;
    }
    const totalPrice = calculateTotalPriceSum(cartItems);
    if (totalPrice >= freeShippingThreshold) {
      return shippingCharge;
    }

    let isDomesticShipping = true;
    if (selectedAddressId) {
      isDomesticShipping = selectedAddressId.type === "Domestic";
    }

    let shippingRates;
    if (isDomesticShipping) {
      shippingRates = domesticShippingRates;
    } else {
      shippingRates = internationalShippingRates;
    }

    if (shippingRates) {
      if (totalCDCount <= 2) {
        shippingCharge += shippingRates.upTo2;
      } else if (totalCDCount <= 5) {
        shippingCharge += shippingRates.threeTo5;
      } else if (totalCDCount <= 8) {
        shippingCharge += shippingRates.sixTo8;
      } else if (totalCDCount <= 10) {
        shippingCharge += shippingRates.nineTo10;
      }
    }

    return shippingCharge;
  };

  return (
    <section className="py-3 md:py-7 px-5 md:px-7 lg:px-12 rounded-md shadow-sm bg-white/[0.7] flex flex-col gap-5 w-full h-min">
      <h1 className="text-2xl font-bold">Order Summary</h1>
      {cartData &&
        cartData.order_items?.map((item) => (
          <div
            className="flex flex-col gap-2 shadow-sm px-4 py-2 rounded-sm"
            key={item._id}
          >
            <div className="flex items-center flex-wrap gap-2 w-full">
              <div className="flex flex-1 items-center gap-2">
                <div className="bg-black/[0.075] h-16 w-16 rounded-md flex items-center">
                  <img
                    src={`https://pancham-bucket.s3.amazonaws.com/${JSON.parse(item.product_details.product_images)[0]
                      }`}
                    alt=""
                    className="object-fit w-full"
                  />
                </div>
                <div>
                  <h2>{item.product_details.title}</h2>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <div className="text-lg">
                  {item.quantity} x ₹{item.product_details.selling_price} = ₹
                  {item.total_price}
                </div>
              </div>
            </div>
          </div>
        ))}
      <hr />
      <div className="flex justify-between items-center">
        <p className="text-gray-600">Total Cart Value</p>
        <p className="text-xl">
          ₹ {calculateTotalPriceSum(cartData.order_items)}
        </p>
      </div>

      <div className="flex justify-between items-center">
        <p className="text-gray-600">Shipping Charges</p>
        <p className="text-xl">
          ₹ {calculateShippingCharges(cartData.order_items, selectedAddressId)}
        </p>
      </div>

      <div className="flex justify-between items-center">
        <p className="text-gray-600">Total Amount</p>
        <p className="text-2xl">
          ₹{" "}
          {calculateShippingCharges(cartData.order_items, selectedAddressId) +
            calculateTotalPriceSum(cartData.order_items)}
        </p>
      </div>

      <p className="text-small">*Prices are inclusive of all taxes</p>

      <div className="w-full py-2 flex gap-4 items-center">
        <button
          onClick={() => {
            handlePlaceOrder();
          }}
          className="btn-rounded-primary rounded-full flex items-center gap-2 md:text-sm lg:text-base"
        >
          Place Order
        </button>
      </div>
    </section>
  );
};

export default SummaryCard;
